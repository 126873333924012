import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from '../../../data/supabase';
import { useAuth } from '../../../shared/context/useAuth';

function usePatientProfile() {
  const { currentUser } = useAuth();

  const [media, setMedia] = useState([]);
  const [patient, setPatient] = useState([]);

  const getProfile = async (user) => {
    const { data } = await supabase
      .from('patient')
      .select()
      .eq('id_user', user?.id);
    if (data) {
      setPatient(data);
    }
  };

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        getProfile(user);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const getMedia = async () => {
    const { data } = await supabase.storage
      .from('uploads')
      .list(`${currentUser?.id}/certified`, {
        limit: 10,
        offset: 0,
        sortBy: {
          column: 'name',
          order: 'asc',
        },
      });

    if (data) {
      setMedia(data);
    }
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    const { data } = await supabase.storage
      .from('uploads')
      .upload(
        `${currentUser?.id}/certified/diploma.${file?.name?.split('.')[1]}`,
        file,
        {
          upsert: true,
        },
      );

    if (data) {
      toast.success('Diploma atualizado');
      getMedia();
    }
  };
  // Call API to update profile settings changes
  const updateProfile = async (patientData) => {
    try {
       await supabase
        .from('patient')
        .update(
          {
            email: patientData.email,
            mobile_number: patientData.mobile_number,
            telephone_number: patientData.telephone_number,
            street_name: patientData.street_name,
            city: patientData.city,
            state: patientData.state,
            zip_code: patientData.zip_code,
            country: patientData.country,
            neighborhood: patientData.neighborhood,
            name: patientData.name,
            allow: patientData.allow,
          }
        ).eq("id_user", currentUser?.id)
        .select();
          
    } catch (error) {
      toast.error('Erro ao atualizar o perfil');
    }
  };

  const updateFormValue = ({ updateType, value }) => {
    setPatient({ ...patient, [updateType]: value });
  };

  return {
    patient,
    media,
    getMedia,
    uploadFile,
    updateProfile,
    updateFormValue,
  };
}

export default usePatientProfile;
