import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { supabase } from "../../../data/supabase";

function ReviewModal({ extraObject }) {
  const { specialist, patient, setReviews } = extraObject;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rating, setRating] = useState(5);
  
  const onSubmit = async (data) => {
    const formData = { ...data, rating, id_spec: specialist?.id, id_pac: patient };
    debugger;
    try {
      const { error } = await supabase.from('reviews').insert([formData]);
      if (error) throw error;

  setReviews((prevReviews) => ({
    ...prevReviews,
    [specialist?.id]: true,
  }));

  toast.success('Avaliação enviada com sucesso!');

      const { data: newReviews } = await supabase.from('reviews').select('*');
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error saving review:', error.message);
      alert('Failed to submit review');
    }
  };

  const onError = () => {
    toast.error("algo deu errado")
  };

  if (isSubmitted) {
    return (
      <div className="w-full max-w-md p-6 bg-white rounded-lg text-center">
        <h2 className="text-lg font-bold text-gray-800">Obrigado pela sua avaliação!</h2>
        <p className="text-gray-500">Seu feedback foi enviado com sucesso.</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="space-y-4">
      <div className="w-full max-w-md  text-center bg-white rounded-lg ">
        <p className="text-sm text-gray-500">Clique na estrela para avaliar <br /> <span className="font-bold text-lg from-neutral-900">{specialist?.name}</span></p>

        <div className="rating mb-6 mt-4">
          {[...Array(5)].map((_, index) => (
            <input onClick={() => setRating(index + 1)} key={index} type="radio" name="rating-1" className="mask mask-star" />
          ))}
        </div>

        <div className="space-y-1">
          <div className="grid  text-left">
            <input type="text" {...register('name', { required: 'Nome necessário' })} placeholder="Seu Nome" className="input input-bordered w-full" />
            <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>
          </div>
          <input type="text" {...register('headline', { required: 'Titulo necessário' })} placeholder="Escolha um titulo para sua avaliação" className="input input-bordered w-full" />
          <p className="text-red-500 text-sm text-left">{errors.headline?.message}</p>
          <textarea placeholder="Escreve sua avalição" {...register('review', { required: 'Escreva uma avalição' })} className="textarea textarea-bordered w-full" rows="4"></textarea>
          <p className="text-red-500 text-sm text-left ">{errors.review?.message}</p>
        </div>

        <button type="submit" className="btn btn-primary w-full mt-4">Enviar avaliação</button>
      </div>
    </form>
  )
}

export default ReviewModal;
