import axios from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from '../../../data/supabase';

function usePayment() {
  const [products, setProducts] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [user, setUser] = useState({});

  const getProducts = async () => {
    axios
      .get(`${process.env.REACT_APP_URL_BACKEND}/api/products`)
      .then((response) => setProducts(response.data))
      .catch((error) => {
        console.log(error);
      });
  };



  const getProfile = async (user) => {
    try {
      const { data: patient } = await supabase
        .from('patient')
        .select()
        .eq('id_user', user.id);

      if (patient) {
        return patient;
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        setUser(user);
        return await getProfile(user);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getLastAmountVoucher = async (patientId, type) => {
    const data = await supabase
      .from('balance')
      .select('voucher')
      .eq('patient_id', patientId)
      .eq('type', type)
      .order('id', { ascending: false })
      .limit(1);

    return data?.data[0]?.voucher;

  };

  const getLastVoucher = async (patientId) => {
    const data = await supabase
      .from('balance')
      .select('type, voucher.max()')
      .eq('patient_id', patientId)

    setVoucher(data?.data);

  };

  // Call API to update schedule settings changes
  const balanceUpdate = async (balance) => {
    try {
      const patient = await getUser();
      const lastVoucher = await getLastAmountVoucher(patient[0]?.id, balance.voucher.type);
      const upsertData = {
        patient_id: patient[0]?.id,
        voucher: lastVoucher ? lastVoucher + balance.voucher.quantity : balance.voucher.quantity,
        type:  balance.voucher.type
      };
      await supabase.from('balance').insert(upsertData).select();

      toast.success('Crédito adicionado com sucesso!');
    } catch (error) {
      toast.error('Erro ao creditar!');
    }
  };


  useEffect(() => {
    const getBalance = async () => {
      const patient = await getUser();
      if (patient?.length > 0) {
        getLastVoucher(patient[0]?.id);
      }
    };
    getBalance();
    getProducts();
  }, []);

  return {
    products,
    balanceUpdate,
    voucher,
    user,
    getUser,
  };
}

export default usePayment;
