import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import handleMeeting from '../../../data/appointments';
import { supabase } from '../../../data/supabase';
import usePatientProfile from '../../../modules/patients/hooks/UsePatientProfile';
import { useAuth } from '../../context/useAuth';

function ConfirmationModalBody({ extraObject, closeModal }) {
  const { message } = extraObject;
  const { user } = useAuth();
  const { patient } = usePatientProfile();
  const [balance, setBalance] = useState([]);
  const [allow, setAllow] = useState(false);
  const [priceCategory, setPriceCategory] = useState();
  const [typeVoucher, setTypeVoucher] = useState('');

  const getBalancePatient = async () => {
    const { data } = await supabase
      .from('balance')
      .select('type, voucher.max()')
      .eq('patient_id', patient[0]?.id);

    if (data) {
      setBalance(data);
    }
  };

  const getPrices = () => {
    const data = axios
      .get(`${process.env.REACT_APP_URL_BACKEND}/api/products`)
      .then((response) => response.data.data)
      .catch((error) => {
        console.log(error);
      });

    return data;
  };

  const {
    data: prices,
    isLoading,
  } = useQuery({
    queryKey: ['prices'],
    queryFn: getPrices,
  });

  const filterPrices = prices?.filter(
    (item) => item.product.active === true && item.active === true,
  );

  useEffect(() => {
    getBalancePatient();
    if(balance && balance.length){
      setAllow(balance?.filter((item) => item.type === priceCategory)[0]?.max  >  0);
    }
  }, [priceCategory]);

  const handlePrices = (event) => {
    setPriceCategory(event.target.value);
    setTypeVoucher(event.target.value);
  };

  return (
    <>
      <div className="stat-title text-center">Confirmação do Agendamento</div>
      <p className="stat-value text-center">{`${message.time}`}</p>

      <div className="stat-desc text-center">
        {`Terapeuta: ${message.nameSpec}`}{' '}
      </div>

      <label className="form-control">
        <div className="label">
          <span className="label-text mt-3 mb-3">Escolha a Categoria</span>
        </div>
        <select className="select select-bordered" onChange={handlePrices}>
          <option selected>Selecione</option>
          {filterPrices?.map((item) => (
            <option
              value={item.product.unit_label}
              label={item.product.active && item.product.name}
            />
          ))}
        </select>
      </label>

      <div className="modal-action mt-12">
        <button className="btn btn-outline" onClick={() => closeModal()}>
          Cancelar
        </button>

        {allow && (
          <button
            disabled={isLoading}
            onClick={() =>
              handleMeeting(
                message,
                closeModal,
                user,
                balance,
                typeVoucher,
              )
            }
            className={`rounded-[8px] text-white font-bold p-[12px] ${
                allow
                ? 'bg-[#3BBED0] border-[#3BBED0]'
                : 'bg-[#616161] border-[#616161] cursor-not-allowed'
            } `}
          >
            Confirmar
          </button>
        )}

        {!allow && (
          <button
          className="rounded-[8px] text-white font-bold p-[12px]

            bg-[#e9e9e9] border-[#e9e9e9]
          "
          >

            <span className='text-slate-500'>Saldo Insuficiente</span>

            <span className='text-slate-400'> | </span>
            <a href='/pagamento'>

            <span className='text-green-600'>Compre créditos</span>
            </a>
          </button>

        )}
      </div>
    </>
  );
}

export default ConfirmationModalBody;
