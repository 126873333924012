import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TitleCardDefault from '../../../../shared/components/Cards/TitleCardDefault';
import { supabase } from '../../../../data/supabase';

function Patients() {
  const {state} = useLocation();
  const [voucher, setVoucher] = useState([]);

   const getLastVoucher = async (patientId) => {
      const data = await supabase
        .from('balance')
        .select('type, voucher.max()')
        .eq('patient_id', patientId)
  
      setVoucher(data?.data);
  
    };

  useEffect(() => {
    getLastVoucher(state?.patient?.id);
    }, []);

  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault title="Detalhes do Paciente" subtitle="Dados Pessoais" description="Visualização dos dados cadastrais dos clientes cadastrados." />
      <div className="stats stats-horizontal lg:stats-horizontal shadow">
        <div className="stat">
          <div className="stat-title">Saldo individual</div>
          <div className="stat-value">{voucher && voucher[0] ? (`${voucher[0]?.max} - ${voucher[0]?.type} `) : <span className='text-[20px] text-[#666]'>Sem saldo</span>}</div>
          <div className="stat-desc">Use seu saldo para terapia individuais</div>
        </div>
        <div className="stat">
          <div className="stat-title">Saldo Casal</div>
          <div className="stat-value">{voucher && voucher[1] ? (`${voucher[1]?.max} - ${voucher[1]?.type} `) : <span className='text-[20px] text-[#666]'>Sem saldo</span>}</div>
          <div className="stat-desc">Use seu saldo para terapia em casal</div>
        </div>
        <div className="stat">
          <div className="stat-title">Saldo Familia</div>
          <div className="stat-value">{voucher && voucher[2] ? (`${voucher[2]?.max} - ${voucher[2]?.type} `) : <span className='text-[20px] text-[#666]'>Sem saldo</span>}</div>
          <div className="stat-desc">Use seu saldo para terapia em familía</div>
        </div>
      </div>
      <form>
          <div className="grid grid-cols-1 desktop:grid-cols-2 gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Nome Completo
                </span>
              </label>
              <input
                type="text"
                name="name"
                value={state?.patient?.name}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Email</span>
              </label>
              <input
                type="text"
                name="email"
                value={state?.patient?.email}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Celular</span>
              </label>
              <input
                type="text"
                name="mobile_number"
                value={state?.patient?.mobile_number}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Telefone</span>
              </label>
              <input
                type="text"
                name="telephone_number"
                value={state?.patient?.telephone_number}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Endereço</span>
              </label>
              <input
                type="text"
                name="street_name"
                value={state?.patient?.street_name}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Complemento
                </span>
              </label>
              <input
                type="text"
                name="neighborhood"
                value={state?.patient?.neighborhood}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Cidade</span>
              </label>
              <input
                type="text"
                name="city"
                value={state?.patient?.city}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Estado</span>
              </label>
              <input
                type="text"
                name="state"
                value={state?.patient?.state}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Código Postal
                </span>
              </label>
              <input
                type="text"
                name="zip_code"
                value={state?.patient?.zip_code}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">País</span>
              </label>
              <input
                type="text"
                name="country"
                value={state?.patient?.country}
                className="input input-bordered w-full"
                readOnly
              />
            </div>
          </div>
        </form>
    </div>
  );
}

export default Patients;

