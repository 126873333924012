import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    role: '',

  },
  reducers: {
    getUser: (state, action) => {
      const { role } = action.payload;
      state.role = role;
    },
  },
});


export default userSlice.reducer;
