import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { supabase } from '../../../../data/supabase';
import { MultipleSelectChip } from '../../../../shared/components/MultiSelect/MultiSelect';
import { useFilter } from '../../../../shared/context/filterContext';
import { useAuth } from '../../../../shared/context/useAuth';
import { openModal } from '../../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../../utils/globalConstantUtil';
import HorizontalCalendar from '../../pages/Specialists/HorizontalCalendar';
import Image from './ImageAvatar/Image';

const CardSpecialist = ({ specialistData, setFavoritesPatient = () => { } }) => {
  const {
    searchQuery,
    setSearchQuery,
    selectedCategory,
    setSelectedCategory,
    setFilterTags,
    filterTags
  } = useFilter();

  const { patient } = useAuth();
  const [searchValue] = useState("");
  const [schedules, setSchedule] = useState();
  const [fetchError, setFetchError] = useState();
  const [favorites, setFavorites] = useState({});

  useEffect(() => {
    const getFavorites = async () => {
      const { data: patient_favorite } = await supabase
        .from('patient_favorite')
        .select('*')
        .eq('id_patient', patient[0]?.id)
        .order('id', { ascending: false })
        .limit(1);

      if (patient_favorite && patient_favorite[0]?.list_favorite) {
        const favoritesData = patient_favorite[0]?.list_favorite.reduce((acc, { id }) => {
          acc[id] = true;
          return acc;
        }, {});
        setFavorites(favoritesData);
      }
    };

    if (patient) {
      getFavorites();
    }
  }, [patient]);

  const idSpec = specialistData?.map((spec) => spec?.id);

  const addFavoriteSpec = async (id) => {
    const updatedFavorites = { ...favorites, [id]: true };

    await supabase
      .from('patient_favorite')
      .upsert({
        id_patient: patient[0]?.id,
        list_favorite: Object.keys(updatedFavorites).map((key) => ({ id: key, isFavorite: true })),
      })
      .eq('id_patient', patient[0]?.id);

    setFavorites(updatedFavorites); // Atualize o estado local imediatamente
    setFavoritesPatient(specialistData.filter(spec => updatedFavorites[spec.id_user])); // Atualize a lista de favoritos

    toast.success('Especialista adicionado aos favoritos!');
  };

  const removeFavorite = async (id) => {
    const updatedFavorites = { ...favorites };
    delete updatedFavorites[id]; // Remova o especialista dos favoritos

    await supabase
      .from('patient_favorite')
      .update({
        id_patient: patient[0]?.id,
        list_favorite: Object.keys(updatedFavorites).map((key) => ({ id: key, isFavorite: true })),
      })
      .eq('id_patient', patient[0]?.id);

    setFavorites(updatedFavorites); // Atualize o estado local imediatamente
    setFavoritesPatient(specialistData.filter(spec => updatedFavorites[spec.id_user])); // Atualize a lista de favoritos
    toast.success('Especialista removido com sucesso!');
  };

  const isFavorite = (id) => {
    return favorites[id] === true; // Verifique se o especialista está nos favoritos
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      const { data, error } = await supabase
        .from('schedule')
        .select()
        .in('specialist_id', idSpec);

      if (error) {
        fetchError(error);
        setSchedule([]);
      }

      if (data) {
        setSchedule(data);
        setFetchError(null);
      }
    };
    fetchSchedule();
  }, [idSpec?.length > 0]);


  const mergeData = schedules?.map((item) => {
    return {
      ...item,
      ...specialistData?.find((item2) => item2.id === item.specialist_id),
    };
  });

  const filteredArr = mergeData?.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    }
    return acc
  }, []);

  const getNextAvailableDate = (availability) => {
    const today = new Date();
    const daysOfWeek = [...Array(7).keys()];

    for (let day of daysOfWeek) {
      const entry = availability.find(avail => avail.dayOfWeek === day && avail.enabled);
      if (entry) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + ((day + 7 - today.getDay()) % 7));
        return nextDate; // Retorna a próxima data disponível
      }
    }
    return null; // Se não houver disponibilidade
  };

  const filteredData = filteredArr
    ?.filter(item => item.allow)
    .map(item => ({
      ...item,
      nextAvailableDate: getNextAvailableDate(item.schedule_availability)
    }))
    .filter(item => item.nextAvailableDate !== null); // Remove itens sem data disponível

  // Ordena pela próxima data disponível mais recente
  const datas = filteredData?.sort((a, b) => a.nextAvailableDate - b.nextAvailableDate);

  const [sortedData, setSortedData] = useState(datas);
  const dispatch = useDispatch()

  useEffect(() => {
    const newSpecialists = datas?.filter(value => value?.name?.toLowerCase().includes(searchValue.toLowerCase()))
    setSortedData(newSpecialists);
  }, [searchValue])

  const categories = [
    'Adaptação ao Novo País/Local',
    'Ansiedade',
    'Depressão',
    'Estresse',
    'Medos e Fobias',
    'Morte e Luto',
    'Sexualidade e Identidade de Gênero',
    'TDAH',
    'TOC',
    'Transtorno de Humor'
  ];

  const handleTypeSession = (type) => {
    return (
      <>
        {type?.value === 'individual' && (
          <div className="badge badge-warning gap-2 p-3 flex mb-2 text-[#303030] ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
            Individual
          </div>

        )}
        {
          type?.value === 'casal' && (

            <div className="badge badge-warning gap-2 p-3 flex mb-2 text-[#303030]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
              </svg>
              Casal
            </div>
          )}

        {type?.value === 'familia' && (

          <div className="badge badge-warning gap-2 p-3 flex mb-2 text-[#303030]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
            </svg>
            Família
          </div>
        )}

      </>
    )
  }

  const handleRadioChangeSkills = (checked, element) => {
    if (checked) {
      setFilterTags([...filterTags, element])
    } else {
      setFilterTags(
        filterTags.filter((filterTag) => filterTag !== element)
      )
    }
  }

  const handleRadioChangeCategories = (category) => {
    setSelectedCategory(category)
  };

  const getFilteredSpecialist = () => {
    let filteredSpecialist = sortedData ? sortedData : mergeData;

    if (selectedCategory) {
      filteredSpecialist = mergeData?.filter(
        (specialist) => {
          return specialist?.typeSession?.some(session => session?.value?.toLowerCase() === selectedCategory?.toLowerCase())
        }
      );
    }

    if (searchQuery) {

      filteredSpecialist = mergeData.filter((specialist) => {
        return specialist?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
      }
      );
    }

    if (filterTags.length > 0) {
      filteredSpecialist = mergeData?.filter(
        (specialist) => {
          return filterTags.every((filterTag) =>
            specialist?.skills?.map((skill) => skill?.label?.toUpperCase()).includes(filterTag?.toUpperCase())
          )
        }
      );
    }

    return filteredSpecialist
  }

  const filteredProducts = getFilteredSpecialist();


  const [ratings, setRatings] = useState({});

  useEffect(() => {
    const fetchRatings = async () => {
      const { data, error } = await supabase
        .from('reviews')
        .select('id_spec, rating');

      if (error) {
        console.error(error);
        toast.error('Erro ao carregar avaliações');
        return;
      }

      // Calcular a média dos ratings para cada especialista
      const ratingsMap = {};
      data.forEach((review) => {
        if (!ratingsMap[review.id_spec]) {
          ratingsMap[review.id_spec] = { total: 0, count: 0 };
        }
        ratingsMap[review.id_spec].total += review.rating;
        ratingsMap[review.id_spec].count += 1;
      });

      // Transformar em média
      const averageRatings = Object.keys(ratingsMap).reduce((acc, id) => {
        acc[id] = ratingsMap[id].total / ratingsMap[id].count;
        return acc;
      }, {});

      setRatings(averageRatings);
    };

    fetchRatings();
  }, []);


  function handleReviewSpecialist(specialist) {
    dispatch(
      openModal({
        title: 'Veja as avaliações',
        bodyType: MODAL_BODY_TYPES.REVIEW_SPEC,
        extraObject: {
          specialist,
        },
      }),
    );
  }

  return (
    <>
      {fetchError && <p>{fetchError}</p>}
      <div className="my-4">

        <div className="flex flex-col">
          <div className="rounded-xl border border-gray-200 bg-white p-4 shadow-lg">
            <div>
              <div className="relative mb-2 w-full flex  items-center justify-between rounded-md">
                <svg className="absolute left-2 block h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="11" cy="11" r="8" className=""></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65" className=""></line>
                </svg>
                <input type="text" name="search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" placeholder="Procure por nome" />
              </div>
              <div className="collapse">
                <div className="collapse-title text-xl font-medium text-end">
                  <button className="btn btn-active btn-neutral bg-[#3BBED0] border-none">+ filtros</button>

                </div>
                <input type="checkbox" />
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    <MultipleSelectChip title="Busque por categoria" onChange={(e) => handleRadioChangeCategories(e?.label)} />

                    <div className="flex flex-col">
                      <label for="manufacturer" className="text-lg font-medium text-stone-600 mb-6">Busque por Especialidades:</label>
                      {categories?.map((item, index) => {
                        return (
                          <div className="form-check" key={index}>
                            <div className='form-control flex flex-row gap-2 my-1'>
                              <input
                                type="checkbox" className="checkbox mr-2"
                                onChange={(e) => handleRadioChangeSkills(e.target.checked, item)}
                              />
                              {item}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        filteredProducts?.length === 0 && (<div className='w-full mt-12'><p className='text-center flex justify-center'><svg className='mr-3 size-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        </svg>
          Nao encontrado</p></div>)}

      {filteredProducts?.map((item) => {
        return (
          <>
            {item?.allow && (
              <div className='w-full flex mobile:flex mobile:flex-col'>
                <div className="bg-white dark:text-white dark:bg-base-100 rounded-l-2xl mobile:rounded-tl-2xl mobile:rounded-tr-2xl mobile:rounded-b-none desktop:p-[56px] p-[32px] mb-4 mobile:mb-0 w-1/2 mobile:w-full">
                  <div className="flex align-center mb-8">
                    <Image
                      sourceImage={item?.avatar_url}
                      userId={item?.id_user}
                      name={item?.name}
                    />
                    <div className=" ml-2">
                      <h4

                        className="text-[#0E0E2C] dark:text-white text-[22px] font-work font-medium flex gap-1 items-center"
                      >
                        {item?.name}
                        {!isFavorite(item.id_user) ? (
                          <div className="tooltip  tooltip-bottom" data-tip="Adicionar aos favoritos">
                            <svg xmlns="http://www.w3.org/2000/svg" className='cursor-pointer w-6 h-6 border-red-700' onClick={() => addFavoriteSpec(item.id_user)} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" >
                              <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                            </svg>
                          </div>
                        ) : (
                          <div className="tooltip  tooltip-bottom" data-tip="Remover dos favoritos">
                            <svg xmlns="http://www.w3.org/2000/svg" className='cursor-pointer w-6 h-6 border-red-700' onClick={() => removeFavorite(item.id_user)} viewBox="0 0 24 24" fill="red" >
                              <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                            </svg>
                          </div>

                        )}
                      </h4>
                      <div className="tooltip cursor-pointer tooltip-bottom" onClick={() => handleReviewSpecialist(item)} data-tip="Veja as avaliações">
                        <div className="rating">
                          {[...Array(5)].map((_, index) => (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill={index < Math.round(ratings[item.id]) ? "orange" : "gray"}
                              className="size-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ))}
                        </div>
                      </div>

                    </div>

                  </div>
                  <div className="mb-12 font-thin">{item?.about}</div>
                  {item?.typeSession?.map((type) => {

                    return handleTypeSession(type)
                  })}



                  {item?.skills && (
                    <span className="text-[#818181] font-bold">
                      Area de atendimento:
                    </span>
                  )}

                  <div className="mt-4 flex flex-wrap gap-1">
                    {item?.skills?.map((item) => {
                      return (
                        <div className="bg-[#ECEDF0] rounded-[100px]  px-3 items-center">
                          <span className="text-[#818181] font-semibold  text-sm ">
                            {item.label.toLowerCase()}
                          </span>
                        </div>
                      );
                    })}
                  </div>



                </div>
                <div
                  className={`dark:text-white ${item?.skills?.length >= 5 ? 'py-24' : 'py-4'} bg-slate-200 mb-4 dark:bg-base-100 rounded-r-2xl mobile:rounded-bl-2xl mobile:rounded-br-2xl mobile:rounded-t-none  mobile:rounded-b-2xl px-2 mobile:w-full  justify-center items-center w-1/2`}
                >
                  <HorizontalCalendar
                    nameSpec={item.name}
                    emailSpec={item.email}
                    schedules={item.schedule}
                    idSpec={item.id}
                    allData={mergeData}
                  />
                </div>
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

export default CardSpecialist;
